body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.icon {
  font-size: 28px;
}

.App {
  text-align: center;
  position: relative;
  /* border-width: 1px; */
  /* border-color: #e3e3e3; */
  /* border-style: dashed; */
  padding-bottom: 100%;
  height: 0;
  width: 100%;

}

.App p{
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 10px;
  color: rgba(239, 197, 225, 0.5);
  z-index: -1;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(239, 197, 225, 0.1);
  width: 100% !important;
  height: 100% !important;
}

.full{
  padding-top: 100px;
}

.toolbarContainer {
  flex-direction: column;
  justify-content: space-between;
  background: white;
  width: 100%;
  border-width: 2px;
  border-color: blue;
  border-style: solid;
}
.toolbarContainer .toolbar {
  height: 50;
  display: flex;
  flex-direction: row;
  background: white;
  justify-content: space-between;
  position: relative;
  z-index: 100;
}
.toolbarContainer .toolbar .toolbarLeft {
  display: flex;
}
.toolbarContainer .toolbar .toolbarRight {
  display: flex;
}

.singleTool {
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabBar {
  background: white;
  text-align: center;
  padding: 14px;
  font-size: 12px;
}
.tabBar .tabTitle {
  display: inline-block;
  border-top: 2px solid #000000;
  padding-top: 8px;
  color: black;
}

.toolbar_tool {
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toolbar_tool.active {
  border-bottom: 3px solid #ff6161;
}

.toolbar_tool.selected {
  background: "#e3e3e3";
}

.input-range {
  margin-bottom: 20px;
}
