@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.009ab13e.eot);
  src:  url(/static/media/icomoon.009ab13e.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.1a9d95c6.ttf) format('truetype'),
    url(/static/media/icomoon.1367def4.woff) format('woff'),
    url(/static/media/icomoon.db342573.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-centered:before {
  content: "\E938";
}
.icon-cloud:before {
  content: "\E939";
}
.icon-fill1:before {
  content: "\E93A";
}
.icon-fill2:before {
  content: "\E93B";
}
.icon-fill3:before {
  content: "\E93C";
}
.icon-justified:before {
  content: "\E93D";
}
.icon-leftaligned:before {
  content: "\E93E";
}
.icon-rightaligned:before {
  content: "\E93F";
}
.icon-spiral:before {
  content: "\E940";
}
.icon-squarefill1:before {
  content: "\E941";
}
.icon-squarefill2:before {
  content: "\E942";
}
.icon-squarefill3:before {
  content: "\E943";
}
.icon-symbol:before {
  content: "\E944";
}
.icon-trash:before {
  content: "\E945";
}
.icon-typesize:before {
  content: "\E946";
}
.icon-activity:before {
  content: "\E900";
}
.icon-alarm:before {
  content: "\E901";
}
.icon-bubble:before {
  content: "\E902";
}
.icon-cancel:before {
  content: "\E903";
}
.icon-circle:before {
  content: "\E904";
}
.icon-crop:before {
  content: "\E905";
}
.icon-donotdisturb:before {
  content: "\E906";
}
.icon-down:before {
  content: "\E907";
}
.icon-draw:before {
  content: "\E908";
}
.icon-experimental:before {
  content: "\E909";
}
.icon-facebook:before {
  content: "\E90A";
}
.icon-favourite:before {
  content: "\E90B";
}
.icon-freesketch:before {
  content: "\E90C";
}
.icon-info:before {
  content: "\E90D";
}
.icon-instagram:before {
  content: "\E90E";
}
.icon-joto:before {
  content: "\E90F";
}
.icon-keyboard:before {
  content: "\E910";
}
.icon-laptop:before {
  content: "\E911";
}
.icon-left:before {
  content: "\E912";
}
.icon-like:before {
  content: "\E913";
}
.icon-line:before {
  content: "\E914";
}
.icon-list:before {
  content: "\E915";
}
.icon-lock:before {
  content: "\E916";
}
.icon-login:before {
  content: "\E917";
}
.icon-logout:before {
  content: "\E918";
}
.icon-menu:before {
  content: "\E919";
}
.icon-message:before {
  content: "\E91A";
}
.icon-move:before {
  content: "\E91B";
}
.icon-OK:before {
  content: "\E91C";
}
.icon-picture:before {
  content: "\E91D";
}
.icon-power-switch:before {
  content: "\E91E";
}
.icon-pulse:before {
  content: "\E91F";
}
.icon-redo:before {
  content: "\E920";
}
.icon-right:before {
  content: "\E921";
}
.icon-search:before {
  content: "\E922";
}
.icon-see:before {
  content: "\E923";
}
.icon-settings:before {
  content: "\E924";
}
.icon-shapes:before {
  content: "\E925";
}
.icon-share:before {
  content: "\E926";
}
.icon-sketch:before {
  content: "\E927";
}
.icon-square:before {
  content: "\E928";
}
.icon-star:before {
  content: "\E929";
}
.icon-stickers:before {
  content: "\E92A";
}
.icon-sync:before {
  content: "\E92B";
}
.icon-tag:before {
  content: "\E92C";
}
.icon-text:before {
  content: "\E92D";
}
.icon-text2:before {
  content: "\E92E";
}
.icon-twitter:before {
  content: "\E92F";
}
.icon-undo:before {
  content: "\E930";
}
.icon-up:before {
  content: "\E931";
}
.icon-upload:before {
  content: "\E932";
}
.icon-user:before {
  content: "\E933";
}
.icon-warning:before {
  content: "\E934";
}
.icon-wavyline:before {
  content: "\E935";
}
.icon-wifi:before {
  content: "\E936";
}
.icon-wifioff:before {
  content: "\E937";
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.icon {
  font-size: 28px;
}

.App {
  text-align: center;
  position: relative;
  /* border-width: 1px; */
  /* border-color: #e3e3e3; */
  /* border-style: dashed; */
  padding-bottom: 100%;
  height: 0;
  width: 100%;

}

.App p{
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 10px;
  color: rgba(239, 197, 225, 0.5);
  z-index: -1;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(239, 197, 225, 0.1);
  width: 100% !important;
  height: 100% !important;
}

.full{
  padding-top: 100px;
}

.toolbarContainer {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: white;
  width: 100%;
  border-width: 2px;
  border-color: blue;
  border-style: solid;
}
.toolbarContainer .toolbar {
  height: 50;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  background: white;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: relative;
  z-index: 100;
}
.toolbarContainer .toolbar .toolbarLeft {
  display: -webkit-flex;
  display: flex;
}
.toolbarContainer .toolbar .toolbarRight {
  display: -webkit-flex;
  display: flex;
}

.singleTool {
  height: 70px;
  width: 70px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.tabBar {
  background: white;
  text-align: center;
  padding: 14px;
  font-size: 12px;
}
.tabBar .tabTitle {
  display: inline-block;
  border-top: 2px solid #000000;
  padding-top: 8px;
  color: black;
}

.toolbar_tool {
  height: 70px;
  width: 70px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.toolbar_tool.active {
  border-bottom: 3px solid #ff6161;
}

.toolbar_tool.selected {
  background: "#e3e3e3";
}

.input-range {
  margin-bottom: 20px;
}

/* INITIALIZERS + DEFAULTS
* ============================== */
@font-face {
  font-family: 'AvenirLTOblique';
  src: url(/static/media/33A665_0_0.3a49420e.eot);
  src: url(/static/media/33A665_0_0.3a49420e.eot?#iefix) format("embedded-opentype"), url(/static/media/33A665_0_0.462757a2.woff2) format("woff2"), url(/static/media/33A665_0_0.2f362be0.woff) format("woff"), url(/static/media/33A665_0_0.42f02d73.ttf) format("truetype"); }

@font-face {
  font-family: 'AvenirLTHeavyOblique';
  src: url(/static/media/33A665_1_0.ce27d2a6.eot);
  src: url(/static/media/33A665_1_0.ce27d2a6.eot?#iefix) format("embedded-opentype"), url(/static/media/33A665_1_0.5b2914fe.woff2) format("woff2"), url(/static/media/33A665_1_0.5fd91be9.woff) format("woff"), url(/static/media/33A665_1_0.2b4c859f.ttf) format("truetype"); }

@font-face {
  font-family: 'AvenirLTHeavy';
  src: url(/static/media/33A665_2_0.c0547871.eot);
  src: url(/static/media/33A665_2_0.c0547871.eot?#iefix) format("embedded-opentype"), url(/static/media/33A665_2_0.ade5ac5c.woff2) format("woff2"), url(/static/media/33A665_2_0.59f0ddf3.woff) format("woff"), url(/static/media/33A665_2_0.d7a40c2c.ttf) format("truetype"); }

@font-face {
  font-family: 'AvenirLTLight';
  src: url(/static/media/33A665_3_0.6d2ae35d.eot);
  src: url(/static/media/33A665_3_0.6d2ae35d.eot?#iefix) format("embedded-opentype"), url(/static/media/33A665_3_0.1810e84f.woff2) format("woff2"), url(/static/media/33A665_3_0.6e4c8a7e.woff) format("woff"), url(/static/media/33A665_3_0.004ea083.ttf) format("truetype"); }

@font-face {
  font-family: 'AvenirLTLightOblique';
  src: url(/static/media/33A665_4_0.485e3524.eot);
  src: url(/static/media/33A665_4_0.485e3524.eot?#iefix) format("embedded-opentype"), url(/static/media/33A665_4_0.9c36e11b.woff2) format("woff2"), url(/static/media/33A665_4_0.1c2d6a34.woff) format("woff"), url(/static/media/33A665_4_0.55428309.ttf) format("truetype"); }

@font-face {
  font-family: 'AvenirLTBold';
  src: url(/static/media/340B71_1_0.7205dd24.eot);
  src: url(/static/media/340B71_1_0.7205dd24.eot?#iefix) format("embedded-opentype"), url(/static/media/340B71_1_0.28d40fe9.woff2) format("woff2"), url(/static/media/340B71_1_0.c8a17757.woff) format("woff"), url(/static/media/340B71_1_0.03917fd4.ttf) format("truetype"); }

@font-face {
  font-family: 'AvenirLTRoman';
  src: url(/static/media/37DDC3_0_0.87cc6c40.eot);
  src: url(/static/media/37DDC3_0_0.87cc6c40.eot?#iefix) format("embedded-opentype"), url(/static/media/37DDC3_0_0.1ae987cc.woff2) format("woff2"), url(/static/media/37DDC3_0_0.98dd81e9.woff) format("woff"), url(/static/media/37DDC3_0_0.f53e0d3d.ttf) format("truetype"); }

